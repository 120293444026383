<template>
  <section class="main-wrap">
    <header class="inner-page-header">
      <h1 v-if="isUpdate" class="big-title">Update user</h1>
      <h1 v-else class="big-title">Invite a user</h1>
    </header>

    <form v-if="invite" action="#" class="invite-user-form" @submit="createInvite">
      <div class="input-label-wrap" :class="{ filled: invite.displayName }">
        <input v-model="invite.displayName" required type="text" class="label-focus" :readonly="isUpdate" />
        <p class="label">Name</p>
      </div>

      <div class="input-label-wrap" :class="{ filled: invite.email }">
        <input v-model="invite.email" required type="email" class="label-focus" :readonly="isUpdate" />
        <p class="label">Email</p>
      </div>

      <div class="user-type-wrap">
        <div class="checkbox-wrap">
          <p class="type">Admin</p>
          <p>Manage all projects</p>
          <input v-model="invite.role" required type="radio" name="type" value="admin" @click="hideClientList" />

          <input v-model="invite.role" required type="radio" name="type-focus" class="focus-zone" value="admin" @click="hideClientList" />
        </div>
        <div class="checkbox-wrap">
          <p class="type">Manager</p>
          <p>Manage specific project(s) <br/> Push to Facebook</p>
          <input v-model="invite.role" required type="radio" name="type" value="manager" @click="showClientList(true)" />

          <input v-model="invite.role" required type="radio" name="type-focus" class="focus-zone" value="manager" @click="showClientList(true)" />
        </div>
        <div class="checkbox-wrap">
          <p class="type">Guest</p>
          <p>Manage specific project(s)</p>
          <input v-model="invite.role" required type="radio" name="type" value="guest" @click="showClientList(true)" />

          <input v-model="invite.role" required type="radio" name="type-focus" class="focus-zone" value="guest" @click="showClientList(true)" />
        </div>
      </div>

      <div v-if="clients && clients.length" class="client-selection-wrap" :class="{ show: isActive }">
        <div v-for="(client, index) in clients" :key="index" class="client-selection">
          <div class="client">
            <div class="client-img-wrapper">
              <client-logo :client="client"></client-logo>
            </div>
            <p>{{ client.name }}</p>
          </div>
          <input v-model="invite.clients" type="checkbox" name="clientId" :value="client.id" />
        </div>
      </div>

      <button type="submit" class="bt" :class="{ 'is-loading': isSaving }">
        <div class="bt-text">
          <p v-if="isUpdate">Update</p>
          <p v-else>Send invite</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
        </div>
        <p class="loader">
          &nbsp;
          <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
            <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
              </path>
            </g>
          </svg>
          &nbsp;
        </p>
      </button>

      <div v-if="isUpdate" class="remove-user-wrap" @click.stop.prevent="removeUser()">
        <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.844 5.238a.599.599 0 0 0-.607.612v5.522c0 .346.263.612.607.612a.655.655 0 0 0 .606-.612V5.85a.656.656 0 0 0-.606-.612zM9.159 5.238a.655.655 0 0 0-.607.612v5.522c.02.326.283.591.607.612a.599.599 0 0 0 .606-.612V5.85a.599.599 0 0 0-.606-.612z"
            fill="#637082"
          />
          <path
            d="M14.395 2.222h-3.497V1.63C10.898.734 10.17 0 9.28 0H5.722c-.889 0-1.617.734-1.617 1.631v.592L.608 2.222A.599.599 0 0 0 0 2.833c-.02.327.242.592.566.612h1.111l.647 10.026c.04.856.748 1.549 1.617 1.529h7.116c.869.02 1.577-.673 1.617-1.529l.647-10.026h1.071c.324.02.587-.244.607-.57v-.04a.597.597 0 0 0-.605-.613zm-9.077-.59c0-.225.182-.408.404-.408H9.28c.223 0 .404.183.404.407v.592l-4.366-.001v-.59zm6.146 11.757a.385.385 0 0 1-.384.387h-.02l-7.117.001c-.202.02-.384-.143-.404-.366v-.02l-.627-9.946h9.178l-.627 9.944z"
            fill="#637082"
          />
        </svg>
        <p>Remove User</p>
      </div>
    </form>

    <div class="small-outline-bt dark" @click="browserBack">
      <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
      <p>Back</p>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'

import InvitesDB from '@/firebase/invites-db'
import UsersDB from '@/firebase/users-db'
import AccountsDB from '@/firebase/accounts-db'

import ClientLogo from '@/components/clients/ClientLogo'

import { doAlert } from '@/misc/helpers'

export default {
  head: function () {
    return {
      title: {
        inner: 'Invite users',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} Invite a user`,
          id: 'desc',
        },
      ],
    }
  },

  components: { ClientLogo },

  data: function () {
    return {
      isActive: false,
      invite: {
        account: null,
        displayName: '',
        email: '',
        clients: [],
        role: null,
      },
      selectedUser: null,
      isSaving: false,
      isUpdate: false,
    }
  },

  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'accountMembership', 'clients']),
  },

  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route)) {
          if (this.accountMembership.id === 'FREE') {
            this.$router.push({ name: 'ManageUsers', query: { membershipGuard: true } })
            return
          }

          if (!$route.params.userId) {
            return
          }

          if (!this.account || !this.account[0]) {
            return
          }

          if ($route.params.userId === this.account[0].owner) {
            this.$router.push({ name: 'ManageUsers' })
            return
          }

          if ($route.params.userId === this.user.id) {
            this.$router.push({ name: 'ManageUsers' })
            return
          }

          if (this.user.id !== this.account[0].owner && this.account[0].usersRoles[$route.params.userId].role === 'admin') {
            this.$router.push({ name: 'ManageUsers' })
            return
          }

          const userId = $route.params.userId
          const usersDbRef = new UsersDB()
          const selectedUser = await usersDbRef.read(userId)

          // If the user does not exists, this is a pending invite
          if (!selectedUser) {
            this.$router.push({ name: 'ManageUsers' })
            return
          }

          this.refreshClientList()

          this.selectedUser = selectedUser

          this.isUpdate = true

          this.invite = {
            account: this.account[0],
            displayName: selectedUser.displayName,
            email: selectedUser.email,
            clients: this.account[0].usersRoles[userId].clients,
            role: this.account[0].usersRoles[userId].role,
          }

          if (this.account[0].usersRoles[userId].role === 'guest') {
            this.isActive = true
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    showClientList(force = false) {

      if (force) {
        this.isActive = true
        return
      }

      if (this.isActive) {
        this.isActive = false
      } else {
        this.isActive = true
      }
    },

    browserBack() {
      return this.$router.go(-1)
    },

    hideClientList() {
      if (this.isActive) {
        this.isActive = false
      }
    },

    async createInvite(e) {
      e.preventDefault()

      if (this.isSaving) {
        return
      }

      this.isSaving = true

      if (this.isUpdate) {
        this.updateUserAccountRoles()
        return
      }

      this.invite.account = this.account[0]

      const usersDbRef = new UsersDB()
      const emailAlreadyExists = await usersDbRef.find([['email', '==', this.invite.email]])

      this.invite.emailAlreadyExists = emailAlreadyExists && emailAlreadyExists.length

      // const checkInvites = new InvitesDB()
      // const inviteAlreadyExists = await checkInvites.find([['email', '==', this.invite.email]])

      // if (inviteAlreadyExists && inviteAlreadyExists.length) {
      //   doAlert({
      //     title: `Invite already pending...`,
      //     type: 'success'
      //   })
      //   return
      // }

      try {
        const invitesDbRef = new InvitesDB()
        await invitesDbRef.create(this.invite)

        doAlert({
          title: `Invite sent successfully`,
          type: 'success',
        })

        this.invite = {
          account: null,
          displayName: '',
          email: '',
          clients: [],
          role: null,
        }

        this.isSaving = false
        this.isActive = false

        this.$router.push({ name: 'ManageUsers' })
      } catch (error) {
        doAlert({ title: error.message, type: 'error' })
        this.isSaving = false
        return
      }
    },

    async updateUserAccountRoles() {
      this.invite.account = this.account[0]

      try {
        const accountsDBRef = new AccountsDB()
        let newAccount = await accountsDBRef.read(this.account[0].id)

        // console.log({ newAccount }, this.selectedUser.id)

        const accountUsersRoles = newAccount.usersRoles
        accountUsersRoles[this.selectedUser.id] = {
          role: this.invite.role,
          clients: this.invite.clients,
        }

        // console.log({ accountUsersRoles })

        newAccount = { ...newAccount, usersRoles: accountUsersRoles }

        // console.log({ newAccount })

        newAccount = accountsDBRef.update(newAccount)

        doAlert({
          title: `User updated with success`,
          type: 'success',
        })

        this.isSaving = false
        this.isActive = false

        this.$router.push({ name: 'ManageUsers' })
      } catch (error) {
        doAlert({ title: error.message, type: 'error' })
        this.isSaving = false
        return
      }
    },

    async removeUser() {
      this.$swal({
        title: 'Remove user from account',
        text: `Are you sure you want to remove this user ${this.selectedUser.email}?`,
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/delete.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          try {
            const accountsDBRef = new AccountsDB()
            let newAccount = await accountsDBRef.read(this.account[0].id)

            const accountUsersList = newAccount.usersList
            const accountUsersRoles = newAccount.usersRoles

            newAccount = { ...newAccount, usersRoles: accountUsersRoles, usersList: accountUsersList }

            const indexOfUser = accountUsersList.indexOf(this.selectedUser.id)
            if (indexOfUser !== -1) {
              accountUsersList.splice(indexOfUser, 1)
            }
            delete accountUsersRoles[this.selectedUser.id]

            // console.log({ newAccount })

            newAccount = accountsDBRef.update(newAccount)

            doAlert({
              title: `User updated with success`,
              type: 'success',
            })

            this.isSaving = false
            this.isActive = false

            this.$router.push({ name: 'ManageUsers' })
          } catch (error) {
            doAlert({ title: error.message, type: 'error' })
            this.isSaving = false
            return
          }
        }
      })
    },

    refreshClientList() {
      this.getUserClients()
    },

    ...mapActions('users', ['getUserClients']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';

.inner-page-header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;
}

.small-outline-bt {
  position: absolute;
  top: 90px;
  left: 30px;
}

.invite-user-form {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
  position: relative;

  p {
    color: #637082;
    font-size: 14px;
  }

  .input-label-wrap {
    margin-bottom: 10px;
  }

  .bt {
    margin: 0 auto;
    margin-top: 35px;
    p {
      color: #fff;
    }
  }
}

.user-type-wrap {
  display: flex;
  justify-content: space-between;

  .checkbox-wrap {
    position: relative;
    background-color: #fff;
    border: 1px solid #e0e2e6;
    border-radius: 3px;
    width: calc(33.3% - 5px);
    padding: 20px 15px;
    cursor: pointer;

    input[type='radio'] {
      margin-top: 10px;
    }

    .focus-zone {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 0;
      cursor: pointer;
    }

    p {
      font-size: 12px;

      &.type {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.client-selection-wrap {
  background-color: #fff;
  border: 1px solid #e0e2e6;
  border-radius: 3px;
  margin-top: 10px;
  padding: 10px;
  display: none;
  z-index: -1;
  position: relative;
  top: 20px;
  transition: all 0.3s ease;

  &.show {
    top: 0;
    display: block;
    z-index: 1;
  }

  .client-selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .client {
      display: flex;
      align-items: center;

      .client-img-wrapper {
        margin-right: 10px;
      }

      p {
        font-size: 14px;
        font-weight: 600;
      }

      img {
        width: 40px;
        border-radius: 20px;
      }
    }
  }
}

.remove-user-wrap {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 0 auto;
  width: 110px;
  margin-top: 20px;

  p {
    color: #f12f80;
    font-weight: 600;
    margin-left: 5px;
  }

  svg {
    path {
      fill: #f12f80;
    }
  }
}
</style>
